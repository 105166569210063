import React, { Component } from "react";
import Slider from "react-slick";
import ProductHeroMobile from "./quick-atc-section/ProductHeroMobile";
import VariableHighlightedLabel from "../variableAnimatedDivider";
import FacebookPixel from "../../lib/tracking/facebook-pixel";
import { isMobile } from "react-device-detect";
import { Link, navigate } from "gatsby";
import ProductPriceTabs from "../product/product-price-tabs";
import AddToCartButton from "../form/add-to-cart-button";
import FlavorTabs from "../form/flavorTabs";
import ProductStrengthTabs from "../product/product-strength-tabs"
import _get from "lodash.get";
import _groupBy from "lodash.groupby";
import _sortBy from "lodash.sortby";
import WatchedElement from "../../components/watchedElement";
import { isTestProduct } from "../../lib/helpers";
import Rating from "react-rating";
import { addToCart, readLocalCart } from "../../lib/cart";
import "./quick-atc-section.scss";


class QuickATCSectionMobile extends Component {
  constructor(props) {
    super(props);
    const groupProducts =  _groupBy(this.props.products, function(product){
      return product.node.acf.effect;
    })
    const orderedProducts = {Energize: groupProducts['Energize'], Unwind: groupProducts['Unwind'], Rejuvenate: groupProducts['Rejuvenate'], Balance: groupProducts['Balance']};
    const products = orderedProducts[Object.keys(orderedProducts)[0]];
    const product = products[0].node;
    this.state = {
      groupProducts: orderedProducts,
      products: products,
      product: product,
      slug: product.slug,
      effect: Object.keys(orderedProducts)[0], 
      productPrice: "subscription",
      quantity: 1,
      productStrength: (product.slug.indexOf("vegan-gummies") !== -1 || product.slug.indexOf("face-cream") !== -1) ? "300" : "500"
    };
  }
  productSliderChange(index) {
    const effect = Object.keys(this.state.groupProducts)[index];
    const products = this.state.groupProducts[Object.keys(this.state.groupProducts)[index]];
    const product = this.state.groupProducts[Object.keys(this.state.groupProducts)[index]][0].node;
    this.setState({ effect: effect, products: products, product: product, productVariant: "subscription", productStrength: (product.slug.indexOf("vegan-gummies") !== -1 || product.slug.indexOf("face-cream") !== -1) ? "300" : "500" });
  }
  handleProductStrengthChange = (value) => {
    this.setState({productStrength:value});
  }
  handleProductPriceSelect = (value) => {
    this.setState({productPrice:value});
  };
  componentDidMount() {
    
  };
  handleFlavorChange = (value) => {
    const product = this.state.products.find(p => p.node.slug === value);
    this.setState({product: product.node});
    if(product.node.slug.indexOf("body-lotion") !== -1 || product.node.slug.indexOf("pain-cream") !== -1) {
      this.setState({productStrength: "500"});
    }
    if(product.node.slug.indexOf("face-cream") !== -1 ){
      this.setState({productStrength: "300"});
    }
    this.setState({slug: value});
  };
  handleAddToCart = () => {
    const subProducts = this.state.product.grouped_products_nodes || [];

    // select sub product "subscription" or "simple"
    let product = subProducts.find(p => p.type === this.state.productPrice && p.slug.indexOf(this.state.productStrength) !== -1);

    // save one-off price for "subscription" product
    if (product.type === "subscription") {
      const oneOffProduct = subProducts.find(p => p.type === "simple" && p.slug.indexOf(this.state.productStrength) !== -1);
      const oneOffPrice = _get(oneOffProduct, "price");
      product = { ...product, oneOffPrice };
    }
    /*
     * this.props.data.product - is parent Grouped product
     * product - is child product (Subscription or One-time purchase)
     */
    const productDescription = this.state.product.description;
    const productUrl = window.location.href;
    const productCategories = this.state.product.categories;
    const p_slug = this.state.product.slug;

    const toCartProduct = {
      ...product,
      description: productDescription,
      url: productUrl,
      categories: productCategories,
      p_slug: p_slug
    };
    const localCart = readLocalCart();
    addToCart(localCart, toCartProduct, {
      product_id: product.id,
      quantity: this.state.quantity
    });
    this.props.addNewCartItem(localCart, toCartProduct);
    this.props.handleCartModalOption();
    if (typeof window.ga === "function") {
      window.ga('ec:addProduct', {
        'id': product.id,
        'name': product.name,
        'category': productCategories.map(function(elem){
          return elem.name;
        }).join('/'),
        'variant': product.type,
        'price': product.price,
        'quantity': 1
      });
      window.ga('ec:setAction', 'add');
      window.ga('send', 'event', 'UX', 'click', 'add to cart');  
    }
  };

  
  render() {
    const {allProductBottomLine, notifyMeClick} = this.props;
   
    const product = this.state.product;
    const productBottomLine = allProductBottomLine.filter(function(bottomLine){
      return (bottomLine.domain_key === product.wordpress_id.toString() || bottomLine.domain_key === product.grouped_products_nodes[0].wordpress_id.toString() || bottomLine.domain_key === product.grouped_products_nodes[1].wordpress_id.toString() || (product.grouped_products_nodes.length > 2 && bottomLine.domain_key === product.grouped_products_nodes[2].wordpress_id.toString()) || (product.grouped_products_nodes.length > 3 && bottomLine.domain_key === product.grouped_products_nodes[3].wordpress_id.toString()) || (product.grouped_products_nodes.length > 4 && bottomLine.domain_key === product.grouped_products_nodes[4].wordpress_id.toString()))
    });
    var avg_rating = 0, total_reviews = 0, total_rating = 0;
    productBottomLine.forEach(bottomLine => {
        total_reviews = total_reviews + bottomLine.total_reviews;
        total_rating = total_rating + bottomLine.total_reviews * bottomLine.product_score;
      })
    avg_rating = total_rating/total_reviews;
    let name
    switch (this.state.effect) {
      case "Balance":
        name = "CBD Vegan Gummies"
        break
      case "Energize":
        name = "CBD Oil"
        break
      case "Rejuvenate":
        name = "Topicals"
        break
      case "Unwind":
        name = "CBD Oil"
        break
      default:
        name = "CBD Oil"
        break
    }
    const SamplePrevArrow = props => {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{
            ...style,
            display: "block",
          }}
          onClick={onClick}
        />
      );
    };
    const className = this.props.hasWhite ? "has-white" : "";

    const handleEnterViewport = () => {
      this.slider.slickPlay();
    };

    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      autoplaySpeed: 8000,
      autoplay: false,
      fade: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: className,
      afterChange: this.productSliderChange.bind(this),
      nextArrow: <SamplePrevArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    
    return (
      <section
        className={`section ${
          this.props.hasWhite ? "has-white-background" : "has-yellow-background"
        } is-relative product-atc-mobile`}
        id="quick-atc-secion">
        <div className="container" id="product-hero-atc">
        <WatchedElement handleEnterViewportScrollDown={handleEnterViewport}>
          <Slider ref={slider => (this.slider = slider)} {...settings}>
          { Object.keys(this.state.groupProducts).map(effect =>
                <div key={effect}>
                  <ProductHeroMobile
                    effect={effect}
                    products={this.state.groupProducts[effect]} 
                    handleCartModalOption={this.props.handleCartModalOption}
                    hasWhite={this.props.hasWhite}
                    slug={this.state.slug}
                    handleFlavorChange={this.handleFlavorChange}
                  />
                </div>
            )}
          </Slider>
          </WatchedElement>
          {this.state.product != null ? (
            <div>
              <div>
                <h1 className="has-text-white" style={{marginTop:'50px'}}>
                {this.state.effect}
                </h1>
                <span style={{fontWeight:'bold', margin:'10px 0', textTransform:'uppercase', fontSize:'12px', color:'#fff', display:'block' }} className="effect-name">{name}</span>
                <span
                  className={`product-page-description is-hidden-mobile`}
                  dangerouslySetInnerHTML={{ __html: `${this.state.product.description}` }}
                />

                <div
                  className="is-inline-block mb-4 cursor-pointer atc-review"
                  aria-label="Show reviews">
                  <div className="columns is-mobile is-vcentered is-gapless">
                    <div className="column is-narrow">
                      <Link to={`/products/${this.state.product.slug}`}>
                        <Rating
                          className={`star-rating readonly has-text-white`}
                          readonly={true}
                          initialRating={avg_rating}
                          emptySymbol={
                            <label className="star-rating__star">☆</label>
                          }
                          fullSymbol={
                            <label className="star-rating__star is-selected">
                              ★
                            </label>
                          }
                        />
                      </Link>
                      &nbsp;
                    </div>
                    <div className="column">
                      <p
                        className={`is-inline-desktop has-text-white`}>
                        {avg_rating.toFixed(1)} ({total_reviews} Reviews)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="product-selection">
                {
                    this.state.effect === "Balance" ?
                    <ProductStrengthTabs product={this.state.product} onChange={this.handleProductStrengthChange}/>
                    :
                    false
                  }
                  <FlavorTabs products={this.state.products} onChange={this.handleFlavorChange} value={this.state.product.slug}/>
                  {
                    ( this.state.effect === "Energize" || this.state.effect === "Unwind" )?
                    <ProductStrengthTabs product={this.state.product} value={this.state.productStrength} onChange={this.handleProductStrengthChange}/>
                    :
                    false
                  }
                  <ProductPriceTabs product={this.state.product} productStrength={this.state.productStrength} value={this.state.productPrice} onChange={this.handleProductPriceSelect}/>
                </div>
                <div className="quantity-buttons-container">
                {"outofstock" === product.stock_status ?
                  <div>
                    <button className="button is-primary is-secondary" style={{background: 'transparent', color: '#fff', border: '1px solid #fff', display: 'block', marginBottom: '25px'}}>Sold Out</button>
                    <button className="button is-primary" onClick={notifyMeClick}>Notify me when available</button>
                  </div>
                  :
                  <div>
                  <AddToCartButton onClick={this.handleAddToCart} />
                  <Link
                    to={`/products/${this.state.product.slug}`}
                    className="see-more-link">
                    <button>SEE MORE DETAILS</button>
                  </Link>
                  </div>
                  }
                </div>
              </div>
            </div>
          ) : (
            false
          )}
          <div className="quick-atc-footer">
              <div className="need-guidance">
                  <p><b>Need guidance?</b> Don’t hesitate to contact us at <b><a href="mailto:hello@reasontosmile.com">hello@reasontosmile.com</a></b> or use our <b><a className="my_custom_intercom" href="mailto:hello@reasontosmile.com">live chat</a></b>.</p>
                </div>
            <VariableHighlightedLabel
              className="divide2"
              animatesOnScroll={true}
              color="white"
            />
            <div className="container">
              <div className="columns is-vcentered is-centered is-variable is-12-desktop">
                <div className="column">
                  <img
                    src={require("../../images/bag/shipping.svg")}
                    style={
                      isMobile
                        ? { marginBottom: "0px", marginRight: "5px" }
                        : { marginBottom: "0px" }
                    }
                    className="is-inline is-vertical-align"
                    alt=""
                  />
                  &nbsp;&nbsp;
                  <h4 className="has-text-white is-inline is-vertical-align">
                    1-3 Day Shipping
                  </h4>
                </div>
                <div className="column">
                  <img
                    src={require("../../images/bag/cancel.svg")}
                    style={
                      isMobile
                        ? { marginBottom: "0px", marginRight: "10px" }
                        : { marginBottom: "0px" }
                    }
                    className="is-inline is-vertical-align"
                    alt=""
                  />
                  &nbsp;&nbsp;
                  <h4 className="has-text-white is-inline is-vertical-align">
                    Cancel Anytime
                  </h4>
                </div>
                <div className="column">
                  <img
                    src={require("../../images/bag/satisfaction.svg")}
                    style={{ marginBottom: "0px" }}
                    className="is-inline is-vertical-align"
                    alt=""
                  />
                  &nbsp;&nbsp;
                  <h4 className="has-text-white is-inline is-vertical-align">
                    100% Satisfaction Guaranteed
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default QuickATCSectionMobile;