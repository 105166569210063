import React, { Component } from "react";
import { Link, navigate } from "gatsby";
import Rating from "react-rating";
import _get from "lodash.get";
import _sortBy from "lodash.sortby";
import { addToCart, readLocalCart } from "../../../lib/cart";
import yotpo from "../../../lib/yotpo";
import "./ProductHero.scss";

const SvgEffectImg = props => {
  let effectImage;
    switch (props.effect) {
        case "Unwind":
          effectImage = "unwind"
          break
        case "Energize":
          effectImage = "energize"
          break
        case "Balance":
          effectImage = "balance"
          break
        case "Rejuvenate":
          effectImage = "topicals"
          break  
        default:
          effectImage = "energize"
          break
    }
  
  return (
    <img src={require(`../../../images/theeffect/${effectImage}-white.svg`)} className={`effect-image ${effectImage}`} alt=""/>
  )
}


export default class ProductHeroMobile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      

    }
  }
  
  render() {
    const { effect, products, slug, handleFlavorChange } = this.props
    let sortedProducts = products;
    if(slug !== products[0].node.slug){
     sortedProducts = _sortBy(products, [function(p) { return p.node.slug!==slug; }])
    }
    return (
        <div id="product-hero-atc" className="hero-body">
          <div className={`container ${effect}-body`}>
            <div className="columns is-centered is-vcentered">
            <div className="column is-4 is-offset-2">
                    <figure className="image product-cell-image">
                      <SvgEffectImg effect={effect} />
                     
                      {
                        sortedProducts.map((product,index) => {
                          if(effect === "Rejuvenate"){
                            return <img
                            key={index}
                            src={_get(product.node, "images[3].src")}
                            alt={_get(product.node, "images[3].alt") || product.node.name}
                            className={`atc-image image${index} image-${product.node.slug}`}
                            onClick={() => handleFlavorChange(product.node.slug)}
                          />
                          }else{
                         return <img
                        key={index}
                        src={_get(product.node, "images[0].src")}
                        alt={_get(product.node, "images[0].alt") || product.node.name}
                        className={`atc-image image${index}`}
                        onClick={() => handleFlavorChange(product.node.slug)}
                      />
                          }

                        })
                      }
                    </figure>
              </div>
            </div>
          </div>
        </div>
    )
  }

}
