import React, { Component } from "react";
import "./tab.scss";
export default class Tab extends Component {

    constructor(props) {
        super(props);
        this.state = { 

        };
    }



    render() {
        const { tabs, onChange, value } = this.props;
        return(
            <div className="tabs is-toggle smile-tabs">
            <ul>
            {tabs.map((tab, index) =>
               <li className={tab.value === value?"is-active":""} key={index} onClick={() => onChange(tab.value)}>
                <a>
                  { typeof tab.icon != "undefined" ?
                    <span className="icon">
                      <img src={require(`../../images/effects/${tab.icon}`)} style={"mighty-mango-energize.svg"===tab.icon?{height:'31px',marginRight:'10px'}:{marginRight:'10px'}} alt=""/>
                    </span>
                    :
                    false
                  }
              <span><span>{tab.tag[0]}</span><br/><span style={ tab.type !== "flavor" ?{fontWeight:'normal'}:{}}>{tab.tag[1]}</span></span>
                </a>
              </li>
              )
              }
            </ul>
          </div>
        );
    }

}