import React from "react";
import Tab from "./tab";


const FlavorTabs = (props) => {

    const tabs = props.products.map(function(product){
        let icon;
        switch (product.node.slug) {
            case "cbd-vegan-gummies-passion-fruit-300mg":
                icon = "passion-fruit-balance.svg";
                break;
            case "cbd-vegan-gummies-acai-blast-300mg":
                icon = "acai-blast.svg";
                break;
            case "cbd-vegan-gummies-fresh-watermelon-300mg":
                icon = "fresh-watermelon.svg";
                break;
            case "cbd-topicals-pain-cream":
                icon = "menthol-rejuvenate.svg";
                break;
            case "cbd-topicals-body-lotion":
                icon = "cocoa-butter-rejuvenate.svg";
                break;
            case "cbd-topicals-face-cream":
                icon = "argan-oil-rejuvenate.svg";
                break;
            case "cbd-oil-mighty-mango":
                icon = "mighty-mango-energize.svg";
                break;
            case "cbd-oil-zesty-lemon":
                icon = "zesty-lemon-energize.png";
                break;
            case "cbd-oil-mint-bliss":
                icon = "mint-bliss-unwind.svg";
                break;
            case "cbd-oil-french-vanilla":
                icon = "french-vanilla-unwind.svg";
                break;
            default:
                icon = "passion-fruit-balance.svg";
                break;
          }
        return {value: product.node.slug, icon: icon, tag: product.node.slug.indexOf("topicals") !== -1 ? product.node.name.split(" ") : (product.node.acf.flavor!=null ? product.node.acf.flavor.split(" "):""), type: "flavor"};
    })

  return (
      <div style={{marginBottom:'15px'}}>
        <span style={{fontSize:'12px', color:'#fff', display:'block' }}>Flavor</span>
        <Tab tabs={tabs} onChange={props.onChange} value={props.value}/>
      </div>
  );

};

export default FlavorTabs;