import React from "react";
import Tab from "../form/tab";

const ProductStrengthTabs = (props) => {
      let tabs
        switch (props.product.slug) {
            case "cbd-vegan-gummies-passion-fruit-300mg":
            case "cbd-vegan-gummies-acai-blast-300mg":
            case "cbd-vegan-gummies-fresh-watermelon-300mg":
            case "cbd-topicals-face-cream":
              tabs =  [{ value: "300", tag: ["STANDARD", "300 MG CBD"], slug: props.product.slug, type: "strength" }];
            break;
            case "cbd-topicals-pain-cream":
            case "cbd-topicals-body-lotion":
              tabs =  [{ value: "500", tag: ["500 MG", "500 MG CBD"], slug: props.product.slug, type: "strength" }];
            break;
            default:
              tabs =  [{ value: "500", tag: ["STANDARD STRENGTH", "500 MG CBD"], slug: props.product.slug, type: "strength" },
            { value: "1000", tag: ["FULL STRENGTH", "1000 MG CBD"], slug: props.product.slug, type: "strength" }];
            break;
        }

  return (
    <div style={{marginBottom:'15px'}} className="strength-tab">
    <span style={{fontSize:'12px', color:'#fff', display:'block' }}>Strength</span>
    {tabs.length > 1 ?
    <Tab tabs={tabs} onChange={props.onChange} value={props.value}/>
    :
    <p style={{color:'#fff',fontSize: '12px', marginTop: '10px'}}><span style={{fontWeight:'bold'}}>{tabs[0].tag[0]}</span> {tabs[0].tag[1]}</p>
    }
    </div>

  )
};

export default ProductStrengthTabs;