import React, { Component } from "react";
import Slider from "react-slick";
import ProductHero from "./quick-atc-section/ProductHero";
import VariableHighlightedLabel from "../variableAnimatedDivider";
import FacebookPixel from "../../lib/tracking/facebook-pixel";
import WatchedElement from "../../components/watchedElement";
import { isMobile } from "react-device-detect";
import _get from "lodash.get";
import _groupBy from "lodash.groupby";
import { isTestProduct } from "../../lib/helpers";
import "./quick-atc-section.scss";

class QuickATCSection extends Component {
  constructor (props) {
    super(props);
    this.state = {
      autoplay: true
    };
  }


  render () {
    const handleEnterViewport = () => {
      this.slider.slickPlay();
    };
    const SamplePrevArrow = (props) => {
      const { className, style, onClick } = props;
      return (<div className={className} style={{
        ...style,
        display: "block"
      }} onClick={onClick} />);
    };
    const className = this.props.hasWhite?"has-white":"";

    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      autoplaySpeed: 4000,
      autoplay: false,
      fade: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      className:className,
      nextArrow: <SamplePrevArrow />,
      prevArrow: <SamplePrevArrow />
    };

    let products = this.props.products;
    const groupProducts =  _groupBy(products, function(product){
      return product.node.acf.effect;
    })
    const orderedProducts = {Energize: groupProducts['Energize'], Unwind: groupProducts['Unwind'], Rejuvenate: groupProducts['Rejuvenate'], Balance: groupProducts['Balance']};
    return (
        <section className={`section ${this.props.hasWhite?"has-white-background":"has-yellow-background"} is-relative product-atc-desktop`} id="quick-atc-secion">
          <div className="container">
            <WatchedElement handleEnterViewportScrollDown={handleEnterViewport}>
              <Slider ref={slider => (this.slider = slider)} {...settings}>
              { Object.keys(orderedProducts).map(effect =>
                <div key={effect}>
                    <ProductHero effect={effect} products={orderedProducts[effect]} handleCartModalOption={this.props.handleCartModalOption} addNewCartItem={this.props.addNewCartItem} hasWhite={this.props.hasWhite} allProductBottomLine={this.props.allProductBottomLine} notifyMeClick={this.props.notifyMeClick}/>
                </div>
                )}
              </Slider>
            </WatchedElement>
              <div className="quick-atc-footer">
                <div className="need-guidance">
                  <p><b>Need guidance?</b> Don’t hesitate to contact us at <b><a href="mailto:hello@reasontosmile.com">hello@reasontosmile.com</a></b> or use our <b><a className="my_custom_intercom" href="mailto:hello@reasontosmile.com">live chat</a></b>.</p>
                </div>
              <VariableHighlightedLabel className="divide2" animatesOnScroll={true} color="white" />
              <div className="container">
                <div className="columns is-vcentered is-centered is-variable is-12-desktop">
                  <div className="column">
                    <img src={require("../../images/bag/shipping.svg")} style={isMobile ? { marginBottom: "0px", marginRight:"5px" }:{ marginBottom: "0px" }} className="is-inline is-vertical-align" alt="" />&nbsp;&nbsp;
                    <h4 className="has-text-white is-inline is-vertical-align">1-3 Day Shipping</h4>
                  </div>
                  <div className="column">
                    <img src={require("../../images/bag/cancel.svg")} style={isMobile ? { marginBottom: "0px", marginRight: "10px" }: { marginBottom: "0px" }} className="is-inline is-vertical-align" alt="" />&nbsp;&nbsp;
                    <h4 className="has-text-white is-inline is-vertical-align">Cancel Anytime</h4>
                  </div>
                  <div className="column">
                    <img src={require("../../images/bag/satisfaction.svg")} style={{ marginBottom: "0px" }} className="is-inline is-vertical-align" alt="" />&nbsp;&nbsp;
                    <h4 className="has-text-white is-inline is-vertical-align">100% Satisfaction Guaranteed</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>    
        </section>
    );
  }
}

export default QuickATCSection;
