import React, { Component } from "react";
import { Link, navigate } from "gatsby";
import Rating from "react-rating";
import QuantityButton from "../../form/quantityButton";
import AddToCartButton from "../../form/add-to-cart-button";
import ProductPriceTabs from "../../product/product-price-tabs";
import SelectProductVariant from "../../product/select-product-variant";
import FlavorTabs from "../../form/flavorTabs";
import ProductStrengthTabs from "../../product/product-strength-tabs"
import _get from "lodash.get";
import _sortBy from "lodash.sortby";
import { addToCart, readLocalCart } from "../../../lib/cart";
import yotpo from "../../../lib/yotpo";
import "./ProductHero.scss";

const SvgEffectImg = props => {
  let effectImage;
    switch (props.effect) {
        case "Unwind":
          effectImage = "unwind"
          break
        case "Energize":
          effectImage = "energize"
          break
        case "Balance":
          effectImage = "balance"
          break
        case "Rejuvenate":
          effectImage = "topicals"
          break  
        default:
          effectImage = "energize"
          break
    }
  
  return (
    <img src={require(`../../../images/theeffect/${effectImage}-white.svg`)} className={`effect-image ${effectImage}`} alt=""/>
  )
}

export default class ProductHero extends Component {
  constructor(props) {
    super(props)

    this.state = {
      productPrice: "subscription", 
      products: this.props.products,
      product: this.props.products[0].node,
      quantity: 1,
      productStrength: (this.props.products[0].node.slug.indexOf("vegan-gummies") !== -1 || this.props.products[0].node.slug.indexOf("face-cream") !== -1) ? "300" : "500"
      
      
    }
  }
  handleProductStrengthChange = (value) => {
    this.setState({productStrength:value});
  }
  handleProductPriceSelect = (value) => {
    this.setState({productPrice:value});
  };
  componentDidMount() {
    
  };
  handleFlavorChange = (value) => {
    const product = this.props.products.find(p => p.node.slug === value);
    this.setState({product: product.node});
    if(product.node.slug.indexOf("body-lotion") !== -1 || product.node.slug.indexOf("pain-cream") !== -1) {
      this.setState({productStrength: "500"});
    }
    if(product.node.slug.indexOf("face-cream") !== -1 ){
      this.setState({productStrength: "300"});
    }
    const sortedProducts = _sortBy(this.props.products, [function(p) { return p.node.slug!==product.node.slug; }])
    this.setState({products: sortedProducts});
  };
  handleAddToCart = () => {
    const subProducts = this.state.product.grouped_products_nodes || [];

    // select sub product "subscription" or "simple"
    let product = subProducts.find(p => p.type === this.state.productPrice && p.slug.indexOf(this.state.productStrength) !== -1);

    // save one-off price for "subscription" product
    if (product.type === "subscription") {
      const oneOffProduct = subProducts.find(p => p.type === "simple" && p.slug.indexOf(this.state.productStrength) !== -1);
      const oneOffPrice = _get(oneOffProduct, "price");
      product = { ...product, oneOffPrice };
    }
    /*
     * this.props.data.product - is parent Grouped product
     * product - is child product (Subscription or One-time purchase)
     */
    const productDescription = this.state.product.description;
    const productUrl = window.location.href;
    const productCategories = this.state.product.categories;
    const p_slug = this.state.product.slug;
    
    const toCartProduct = {
      ...product,
      description: productDescription,
      url: productUrl,
      categories: productCategories,
      p_slug: p_slug
    };
    const localCart = readLocalCart();
    addToCart(localCart, toCartProduct, {
      product_id: product.id,
      quantity: this.state.quantity
    });
    this.props.addNewCartItem(localCart, toCartProduct);
    this.props.handleCartModalOption();
    if (typeof window.ga === "function") {
      window.ga('ec:addProduct', {
        'id': product.id,
        'name': product.name,
        'category': productCategories.map(function(elem){
          return elem.name;
        }).join('/'),
        'variant': product.type,
        'price': product.price,
        'quantity': 1
      });
      window.ga('ec:setAction', 'add');
      window.ga('send', 'event', 'UX', 'click', 'add to cart'); 
    }
  };
  render() {
    const { effect, products, hasWhite, allProductBottomLine, notifyMeClick } = this.props;
    const product = this.state.product;
    const productBottomLine = allProductBottomLine.filter(function(bottomLine){
      return (bottomLine.domain_key === product.wordpress_id.toString() || bottomLine.domain_key === product.grouped_products_nodes[0].wordpress_id.toString() || bottomLine.domain_key === product.grouped_products_nodes[1].wordpress_id.toString() || (product.grouped_products_nodes.length > 2 && bottomLine.domain_key === product.grouped_products_nodes[2].wordpress_id.toString()) || (product.grouped_products_nodes.length > 3 && bottomLine.domain_key === product.grouped_products_nodes[3].wordpress_id.toString()) || (product.grouped_products_nodes.length > 4 && bottomLine.domain_key === product.grouped_products_nodes[4].wordpress_id.toString()))
    });
    var avg_rating = 0, total_reviews = 0, total_rating = 0;
    productBottomLine.forEach(bottomLine => {
        total_reviews = total_reviews + bottomLine.total_reviews;
        total_rating = total_rating + bottomLine.total_reviews * bottomLine.product_score;
      })
    avg_rating = total_rating/total_reviews;

    let name
    switch (effect) {
      case "Balance":
        name = "CBD Multivitamin Vegan Gummies"
        break
      case "Energize":
        name = "CBD Oil"
        break
      case "Rejuvenate":
        name = "Topicals"
        break
      case "Unwind":
        name = "CBD Oil"
        break
      default:
        name = "CBD Oil"
        break
    }

    

    return (
        <div id="product-hero-atc" className={`hero-body ${effect}-hero`}>
          <div className={`container ${effect}-body`}>
            <div className="columns is-centered is-vcentered">
              <div className="column is-4 is-offset-1">

                    <figure className="image product-cell-image">
                      <SvgEffectImg effect={effect} />
                     
                      {
                        this.state.products.map((product,index) => {
                          if(effect === "Rejuvenate"){
                            return <img
                            key={index}
                            src={_get(product.node, "images[3].src")}
                            alt={_get(product.node, "images[3].alt") || product.node.name}
                            className={`atc-image image${index} image-${product.node.slug}`}
                            onClick={() => this.handleFlavorChange(product.node.slug)}
                          />
                          }else{
                         return <img
                        key={index}
                        src={_get(product.node, "images[0].src")}
                        alt={_get(product.node, "images[0].alt") || product.node.name}
                        className={`atc-image image${index}`}
                        onClick={() => this.handleFlavorChange(product.node.slug)}
                      />
                          }

                        })
                      }
                    </figure>
              </div>
              <div className="column is-4">
                <h1 className={hasWhite?"":"has-text-white"}>{effect}</h1>
                <span style={{fontWeight:'bold', margin:'10px 0', textTransform:'uppercase', fontSize:'12px', color:'#fff', display:'block' }} className="effect-name">{name}</span>
                <span
                  className={`product-page-description ${hasWhite?"":"has-text-white"} is-hidden-mobile`}
                  dangerouslySetInnerHTML={{ __html: `${this.state.products[0].node.description}` }}
                />

                <div
                  className="is-inline-block cursor-pointer atc-review"
                  aria-label="Show reviews"
                  style={{margin:'10px 0 5px 0'}}
                >
                  <div className="columns is-mobile is-vcentered is-gapless">
                    <div className="column is-narrow">
                      <Link to={`/products/${this.state.products[0].node.slug}`}>
                      <Rating
                        className={`star-rating readonly ${hasWhite?"":"has-text-white"}`}
                        readonly={true}
                        initialRating={avg_rating}
                        emptySymbol={
                          <label className="star-rating__star">☆</label>
                        }
                        fullSymbol={
                          <label className="star-rating__star is-selected">
                            ★
                          </label>
                        }
                      />

                      </Link>
                      &nbsp;
                    </div>
                    <div className="column">
                      <p className={`is-inline-desktop ${hasWhite?"":"has-text-light"}`}>
                        {avg_rating.toFixed(1)} ({total_reviews} Reviews)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="product-selection">
                  {
                    effect === "Balance" ?
                    <ProductStrengthTabs product={this.state.product} onChange={this.handleProductStrengthChange}/>
                    :
                    false
                  }
                  <FlavorTabs products={products} onChange={this.handleFlavorChange} value={this.state.product.slug}/>
                  {
                    ( effect === "Energize" || effect === "Unwind" )?
                    <ProductStrengthTabs product={this.state.product} value={this.state.productStrength} onChange={this.handleProductStrengthChange}/>
                    :
                    false
                  }
                  <ProductPriceTabs product={this.state.product} productStrength={this.state.productStrength} value={this.state.productPrice} onChange={this.handleProductPriceSelect}/>
                </div>
                <div className="quantity-buttons-container">
                {"outofstock" === product.stock_status ?
                  <div>
                    <button className="button is-primary is-secondary" style={{background: 'transparent', color: '#fff', border: '1px solid #fff', display: 'block', marginBottom: '25px'}}>Sold Out</button>
                    <button className="button is-primary" onClick={notifyMeClick}>Notify me when available</button>
                  </div>
                  :
                  <div>
                  <AddToCartButton onClick={this.handleAddToCart} />
                  <Link to={`/products/${this.state.product.slug}`} className="see-more-link"><button>SEE MORE DETAILS</button></Link>
                  <br />
                  <br />  
                </div>
                }
              </div>
              <div className="column is-4">

              </div>
            </div>
          </div>
        </div>
        </div>
    )
  }
}
