import React from "react";
import Tab from "../form/tab";

const ProductPriceTabs = (props) => {    
  const subscriptionProduct = (props.product.grouped_products_nodes || []).find(p => p.type === "subscription" && p.slug.indexOf(props.productStrength) !== -1);
  const subscriptionPrice = subscriptionProduct ? subscriptionProduct.price : null;
  const regularSubscriptionPrice = subscriptionProduct ? subscriptionProduct.regular_price : null;

  const simpleProduct = (props.product.grouped_products_nodes || []).find(p => p.type === "simple" && p.slug.indexOf(props.productStrength) !== -1);
  const simplePrice = simpleProduct ? simpleProduct.price : null;
  const regularSimplePrice = simpleProduct ? simpleProduct.regular_price : null;

  let tabs =  [{ value: "subscription",  tag: ["$"+subscriptionPrice, "SUBSCRIBE & SAVE 15%"], regularPrice: "$"+regularSubscriptionPrice, slug: props.product.slug, type: "price" },
  { value: "simple",  tag: ["$"+simplePrice, "ONE-TIME PURCHASE"], regularPrice: "$"+regularSimplePrice, slug: props.product.slug, type: "price" }];

  return (
    <div className="price-tab">
    <span style={{fontSize:'12px', color:'#fff', display:'block' }}>Price</span>

    <Tab tabs={tabs} onChange={props.onChange} value={props.value}/>

    </div>
  );
}
export default ProductPriceTabs;